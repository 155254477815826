@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600&family=Open+Sans:ital,wght@0,400;0,500;0,600;1,400&family=Pacifico&family=Patua+One&display=swap');





.teclado-custom{
  /* font-family: "Inconsolata";
    font-family: "Patua One";
  font-size: 1.5rem;
    */
  font-family: "Montserrat";
  line-height: 3rem;
}

.teclado-custom .hg-row .hg-button:not(:last-child){
  margin-right: 10px;
}

.teclado-custom  .hg-row:not(:last-child) {
  margin-bottom: 10px;
}

.teclado-custom .hg-button{
  height: 55px;
}

/* Ajusta el tamaño de la tecla de barra espaciadora */
.teclado-custom .hg-functionBtn[data-skbtn="{space}"] {
  width: 80% !important; /* 3/4 del espacio */
}

/* Ajusta el tamaño de la tecla de borrar */
.teclado-custom .hg-functionBtn[data-skbtn="{bksp}"] {
  width: 20%; /* 1/4 del espacio */
}





.teclado-custom-ruc{
  /* font-family: "Inconsolata";
    font-family: "Patua One";
  font-size: 1.5rem;
    */
  font-family: "Montserrat";
  line-height: 3rem;
}

.teclado-custom-ruc .hg-row .hg-button:not(:last-child){
  margin-right: 10px;
}

.teclado-custom-ruc  .hg-row:not(:last-child) {
  margin-bottom: 10px;
}

.teclado-custom-ruc .hg-button.hg-standardBtn {
  width: 75px;
  height: 75px;
}

/* Ajusta el tamaño de la tecla de borrar */
.teclado-custom-ruc .hg-functionBtn[data-skbtn="{bksp}"] {
   /*  width: 20%; 1/4 del espacio */
   width: 75px;
   height: 75px;
}







body > #root > div {
    height: 100vh;

    font-family: "Montserrat";

    background-color: #d4d4d4;
    background-color: #d4d4d4;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23c0b9cd' fill-opacity='0.36'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }

  .svg-icon {
    width: 1em;
    height: 1em;
  }
  
  .svg-icon path,
  .svg-icon polygon,
  .svg-icon rect {
    fill: #4691f6;
  }
  
  .svg-icon circle {
    stroke: #4691f6;
    stroke-width: 1;
  }



  input[type="radio"] + label span {
    transition: background .2s,
      transform .2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span{
  transform: scale(1.2);
} 

input[type="radio"]:checked + label span {
  background-color: #3490DC;  
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked + label{
   color: #3490DC;  
}

